
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "Loved by 200,000+ students";
const title = "Why should one consider Germany?";


const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/01.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: '',
        degi: '',
        desc: 'It is generally observed that a significant majority of Indian students who go to Germany for higher education enrol in STEM fields. This is because Germany is renowned for its strong STEM education and research opportunities, particularly in fields like engineering, computer science, natural sciences, and technology. Many Indian students are attracted to Germany for its high-quality STEM programs, cutting-edge research facilities, and opportunities for career advancement in industries such as automotive, IT, manufacturing, and healthcare    It is common to see a majority of Indian students in Germany pursuing STEM-related degrees. However, there is also a notable and growing number of Indian students opting for Humanities, social sciences, arts, and business-related programs in Germany, attracted by the diverse academic offerings and opportunities for interdisciplinary studies',
    },

]


const Student = () => {
    return (
        <div className="student-feedbak-sections padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="">
                                    <img src="assets/images/feedback/01.jpg" alt="student feedback" />
                                    {/* <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/wNadwJanpy8?si=f5S77x1U8RjAH6qy"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe> */}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    {/* <img src={`${val.imgUrl}`} alt={val.imgAlt} /> */}
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            {/* <div className="sft-right">
                                                <Rating />
                                            </div> */}
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Student;