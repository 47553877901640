import { Component, Fragment, useRef, useState } from "react";
import GoogleMap from "../sidebar/googlemap";
import emailjs from "@emailjs/browser";


const title = <h2 className="title">Need Any <span>Help?</span></h2>;
const desc = "You can contact with us for anykinds of informations and help. We are ready to help you.";


const contactInfoList = [
    {
        imgUrl: 'assets/images/contact/icon/01.png',
        imgAlt: 'contactinfo rajibraj91 rajibraj',
        subTitle: 'Make a Call:',
        title: '+91 93100 72343',
    },
    {
        imgUrl: 'assets/images/contact/icon/02.png',
        imgAlt: 'contactinfo rajibraj91 rajibraj',
        subTitle: 'Send Us Messege at:',
        title: 'info.education@aspirations.global',
    },
]

const subTitle = "";
// const title = "We're Always Eager To Hear From You!";
const conSubTitle = " Contact us";
const conTitle = "Know more about German scholarship and Education.";
const btnText = "Send our Message";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Meet Us',
        desc: '7-8, Ground Floor Vijaya Building,; 17 - Barakhamba Road;  New Delhi- 110001',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+91 93100 72343',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'info.education@aspirations.global',
    },

]
const ContactInfo = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        if (isButtonDisabled) {
            return;
        }

        emailjs
            .sendForm(
                "service_g5m7dqt",
                "template_jcz69as",
                form.current,
                "nEJBaFwaecgplKBny"
            )
            .then(
                (result) => {
                    form.current.reset();
                    setButtonDisabled(true);
                    startCountdown();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    const startCountdown = () => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                    clearInterval(intervalId);
                    setTimeout(() => {
                        setButtonDisabled(false);
                        setCountdown(10);
                    }, 10000);
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };



    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    return (<>

        <div className="contact-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{ color: 'gray' }}>{conSubTitle}</span>
                    <h2 className="title">{conTitle}</h2>
                </div>
                <div className="section-wrapper">
                    <form className="contact-form" ref={form} onSubmit={sendEmail}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="user_name"
                                placeholder="Your Name *"
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="user_email"
                                placeholder="Your Email *"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="user_tel"
                                placeholder="Mobile Number *"
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="user_states"
                                placeholder="Your State *"
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="user_course"
                                placeholder="Your Course *"
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="user_language"
                                placeholder="Your Language*"
                                required />
                        </div>
                        <div className="form-group w-100">
                            <textarea
                                rows="8"
                                type="text"
                                name="user_message"
                                placeholder="Your Message"
                                required ></textarea>
                        </div>
                        <div className="form-group w-100 text-center">
                            <button
                                type="submit"
                                disabled={isButtonDisabled}
                                className="btn btn-primary"
                            >
                                {isButtonDisabled
                                    ? `Sending... (${countdown})`
                                    : "Send Email"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="map-address-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row flex-row-reverse">
                        <div className="col-xl-4 col-lg-5 col-12">
                            <div className="contact-wrapper">
                                {contactList.map((val, i) => (
                                    <div className="contact-item" key={i}>
                                        <div className="contact-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="contact-content">
                                            <h6 className="title">{val.title}</h6>
                                            <p>{val.desc.split(';').map((line, index) => (
                                                <p key={index}>{line.trim()}</p>
                                            ))}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-12">
                            <GoogleMap />
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>);
}


export default ContactInfo



