import { Link } from "react-router-dom";

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const useTitle = "Quick Links";
const socialTitle = "Social Contact";
const supportTitle = "Our Support";


const siteList = [

    {
        text: 'info.education@aspirations.global',
        link: 'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox/FMfcgzQVwnRsFksCcKwqLXBMzXvvVQrS?compose=new',
    },
    {
        text: '+91 93100 72343',
        link: '#',
    },
    {
        text: '',
        link: '#',
    },

]

const useList = [
    {
        text: 'About Us',
        link: '/',
    },
    {
        text: 'Contact Us',
        link: '/',
    },

    {
        text: 'Terms & Conditions',
        link: '/',
    },


]

const socialList = [
    {
        text: 'Facebook',
        link: 'https://www.facebook.com/profile.php?id=61555917730119',
    },
    {
        text: 'InstaGram',
        link: 'https://www.instagram.com/aspirationsincglobal/',
    },


    {
        text: 'LinkedIN',
        link: 'https://www.linkedin.com/company/aspirations-inc-global/',
    },
]

const supportList = [
    {
        text: 'Help Center',
        link: '#',
    },
    {
        text: 'Paid with Mollie',
        link: '#',
    },


    {
        text: 'Contact Support',
        link: '#',
    },
]



const Footer = () => {
    return (
        <div className="news-footer-wrap" style={{ background: '#939799', color: "black" }}>
            <div className="fs-shape">
                <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
                <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
            </div>

            <div className="news-letter">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="news-title">
                            <h3>{newsTitle}</h3>
                        </div>
                        <div className="news-form" style={{ color: "gray" }}>
                            <form action="/">
                                <div className="nf-list">
                                    <input type="email" name="email" placeholder="Enter Your Email" />
                                    <input type="submit" name="submit" value="Subscribe Now" className="" style={{ background: 'gray' }} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer-top padding-tb pt-0">
                    <div className="container">
                        <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <img src="assets/images/logo/01.png" alt="fst" className="fst-2" style={{ background: 'white' }} />


                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {siteList.map((val, i) => (
                                                        <li key={i}><Link to={val.link} target="blank">{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{useTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {useList.map((val, i) => (
                                                        <li key={i}><Link to={val.link}>{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{socialTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {socialList.map((val, i) => (
                                                        <li key={i}><a href={val.link} target="blank">{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{supportTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {supportList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom style-2">
                    <div className="container">
                        <div className="section-wrapper">
                            <p>&copy;  2024 All rights reserved </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;