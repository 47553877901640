import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const phoneNumber = "+91 93100 72343";
const address = "7-8, Ground Floor Vijaya Building, 17 - Barakhamba Road  New Delhi- 110001";


let socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/profile.php?id=61555917730119',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/aspirationsincglobal/',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/aspirations-inc-global/',
    },

]

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [socialToggle, setSocialToggle] = useState(false);
    const [headerFiexd, setHeaderFiexd] = useState(false);

    window.addEventListener("scroll", () => {
        if (window.scrollY > 200) {
            setHeaderFiexd(true);
        } else {
            setHeaderFiexd(false);
        }
    });

    return (
        <header className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li><i className="icofont-whatsapp" style={{ color: 'green' }}></i> <span>{phoneNumber}</span></li>
                            <li><i className="icofont-location-pin" style={{ color: "blue" }}></i> {address}</li>
                        </ul>
                        <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink} target="blank"><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/"><img src="assets/images/logo/01.png" alt="logo" /></Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li className="menu-item">
                                        <NavLink to="/" >Home</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/">Services</NavLink>
                                        {/* course-single */}
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/">Blog </NavLink>
                                        {/* blog-single */}
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/">About</NavLink>

                                    </li>
                                    <li><NavLink to="/">Contact Us</NavLink></li>
                                </ul>
                            </div>

                            {/* <Link to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>
                            <Link to="/signup" className="signup"><i className="icofont-users"></i> <span>SIGN UP</span> </Link> */}

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square" style={{ color: 'gray' }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;