import { Button } from 'bootstrap';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const subTitle = "SOLID FEATURES";
const title = "Our Services";

const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: "Information and Consultation",
        des: 'Our experienced counsellors provide detailed information about the academic programs, admission requirements, scholarships, and student life at SRH UNIVERSITIES. We offer personalized consultations to address the specific needs and interests of individual students and parents.',
    },
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: "Application Assistance",
        des: 'We assist students in preparing their application materials, including transcripts, letters of recommendation, and statement of purpose, to ensure they meet the admission criteria of SRH UNIVERSITIES. Our team guides students through the application process, from initial submission to acceptance.',
    },
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Visa and Immigration Support',
        des: 'Navigating the visa and immigration process can be complex. We provide guidance on visa application procedures, documentation requirements, and interview preparation to help students obtain their student visa for Germany smoothly.',
    },
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Pre-Departure Orientation',
        des: 'To ease the transition for students traveling to Germany, we conduct pre-departure orientation sessions covering topics such as accommodation options, banking, health insurance, and cultural adaptation. We aim to ensure that students are well-prepared for their academic journey abroad.',
    },
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Post-Arrival Support',
        des: 'Our support extends beyond admission, as we offer assistance to students after their arrival in Germany. We provide guidance on campus life, academic resources, part-time job opportunities, and community integration, ensuring a smooth transition and a rewarding student experience.',
    },
    {
        imgUrl: 'assets/images/category/icon/education-icon.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Post-Arrival Support',
        des: 'Our support extends beyond admission, as we offer assistance to students after their arrival in Germany. We provide guidance on campus life, academic resources, part-time job opportunities, and community integration, ensuring a smooth transition and a rewarding student experience.',
    },
];

const Category = () => {
    const [expanded, setExpanded] = useState(Array(categoryList.length).fill(false));

    const toggleContent = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span> */}
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-12 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => {
                            const words = val.des.split(' ');
                            const previewContent = words.slice(0, 16).join(' ');
                            const remainingContent = words.slice(16).join(' ');

                            return (
                                <div className="col" key={i}>
                                    <div className="category-item text-center">
                                        <div className="category-inner">
                                            <div className="icon-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 101, height: 101, marginLeft: 130 }}>
                                                <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                            </div>
                                            <div className="category-content" style={{ color: 'gray' }}>
                                                <Link to="#" style={{ color: 'gray' }}><h6>{val.title}</h6></Link>
                                                <span style={{ color: 'gray', textAlign: 'center' }}>
                                                    {previewContent}
                                                    {expanded[i] && words.length > 16 && <span style={{ color: 'gray', textAlign: 'left' }}> {' '}{remainingContent}</span>}
                                                </span>
                                                {!expanded[i] && words.length > 16 && (
                                                    <span
                                                        style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline', marginTop: 20 }}
                                                        onClick={() => toggleContent(i)}
                                                    >
                                                        {' '}<button className='btn btn-secondary'>Read More</button>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;
