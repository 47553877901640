


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">

                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7004.049287167359!2d77.2245489!3d28.6290235!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd33dbb1e293%3A0x14124d0995cfc072!2sVijaya%20Building!5e0!3m2!1sen!2sin!4v1716706790842!5m2!1sen!2sin" ></iframe>
            </div>
        </div>
    );
}

export default GoogleMap;