


const subTitle = "Germany's Education Consultant in India";
const title = <h2 className="title"><span className="d-lg-block">The starting point for your German career</span> </h2>;
const desc = "We provide all-inclusive student solutions, guaranteeing a 360-degree approach to handling your application procedure and helping you pursue your education at the ideal university in Germany.";


// const catagoryList = [
//     {
//         name: 'Figma',
//         link: '#',
//     },
//     {
//         name: 'Adobe XD',
//         link: '#',
//     },
//     {
//         name: 'illustration',
//         link: '#',
//     },
//     {
//         name: 'Photoshop',
//         link: '#',
//     },
// ]


const shapeList = [
    {
        name: '16M Students Happy',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: '130K+ Total Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: '23M+ Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        name: '36+ Languages',
        link: '#',
        className: 'ccl-shape shape-5',
    },
]

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium" style={{ color: 'gray' }}>{subTitle}</h6>
                                {title}
                                <p className="desc">{desc}</p>
                                <form action="/">
                                    <div className="banner-icon" >
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" />
                                    <button name="submit" className="bg-color-gray" style={{ background: 'gray' }}>Search Course</button>
                                </form>
                                {/* <div className="banner-catagory d-flex flex-wrap">
                                    <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="banner-thumb">
                                <img src="assets/images/banner/01.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes"></div>
            {/* <div className="cbs-content-list d-none">
                <ul className="lab-ul">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                    ))}
                </ul>
            </div> */}
        </section>
    );
}

export default Banner;